<template lang="html">
  <div class="wrapper pageWrapper">
    <div class="projectPageWrapper">

      <section class=" ">
        
        <GoBack/>

        <h1>My Playground</h1>
        <h2 class="subTitle">Playing around</h2>
        <h3 class="projectPageDescription">
          Here you'll find me playing around and experimenting with modern web technologies, industrial, graphic and product design, photography and anything I find interesting.
        </h3>
      </section>
    <div class="projectList">
      <!-- Projects -->
      <Project v-for="project in projects" :key="project.id" :project="project"/>

      <!-- <Project :project="design_bites"/>
      <Project :project="photography"/>
      <Project :project="pride"/>
      <Project :project="gemma"/>
      <Project :project="space"/>
      <Project :project="toDo"/>
      <Project :project="clock"/>
      <Project :project="guessGame"/> -->
      <!-- <Project :project="speaker"/> -->
    </div>

    </div>
    <!-- <MoreProjects :projects="moreProjects"/> -->
  </div>
</template>

<script>
import MoreProjects from "@/components/MoreProjects.vue"
import GoBack from "@/components/GoBack.vue"
import Project from "@/components/Project.vue"

export default {
  name: 'Cocacola',
  data(){
    return{
      moreProjects:{
        previous: {
          title: "China, seen by my lens",
          path: "/china"
        },
        next: {
          title: "Nightmare Valley",
          path: "/Nightmare_Valley"
        }
      },
      projects:{
        stacoverflowFocus:{
          id: "toDo",
          onMouseOver: "onHover('stacoverflowFocus')",
          onMouseOut: "onMouseout('stacoverflowFocus')",
          path: "http://bit.ly/StackOverflow_focus",
          idProject: "stacoverflowFocus",
          img: require("@/assets/images/playground/Stacoverflow_Focus.jpg"),
          tagOne: "#UI_Design",
          tagTwo: "#Front_End",
          title: "Stacoverflow Focus - Browser Plugin",
          description: "Remove all unnecessary clutter from StackOverflow.",
          linkType: "url",
        },

        design_bites:{
          id: "design_bites",
          onMouseOver: "onHover('projectImageDesignBites')",
          onMouseOut: "onMouseout('projectImageDesignBites')",
          path: "/design_bites",
          idProject: "projectImageDesignBites",
          img: require("@/assets/images/designBites.gif"),
          tagOne: "#Graphic_Design",
          tagTwo: "#Visual_Design",
          title: "Design Bites",
          description: "Smaller design protjects.",
          linkType: "router",
        },

        photography:{
          id: "photography",
          onMouseOver: "onHover('projectImagePhotography')",
          onMouseOut: "onMouseout('projectImagePhotography')",
          path: "https://www.instagram.com/elrumo/",
          idProject: "projectImagePhotography",
          img: require("@/assets/images/photography2.jpg"),
          tagOne: "#Photography",
          tagTwo: "#Photoshop",
          title: "Photography",
          description: "Artistic, creative and commercial photography.",
          linkType: "url",
        },

        space:{
          id: "space",
          onMouseOver: "onHover('projectImagePlanets')",
          onMouseOut: "onMouseout('projectImagePlanets')",
          path: "https://elrumo.github.io/planteray-system/",
          idProject: "projectImagePlanets",
          img: require("@/assets/images/space.gif"),
          tagOne: "#Visual_Design",
          tagTwo: "#Front_End",
          title: "Solar System",
          description: "A recreation of a solar system in pure CSS.",
          linkType: "url",
        },

        pride:{
          id: "pride",
          onMouseOver: "onHover('projectImagePride')",
          onMouseOut: "onMouseout('projectImagePride')",
          path: "/pride",
          idProject: "projectImagePride",
          img: require("@/assets/images/pride.svg"),
          tagOne: "#Graphic_Design",
          tagTwo: "#Print_Design",
          title: "It's not a bug, it's a feature",
          description: "Supporting LGBT+ in the tech commnity.",
          linkType: "router",
        },

        clock:{
          id: "clock",
          onMouseOver: "onHover('projectImageClock')",
          onMouseOut: "onMouseout('projectImageClock')",
          path: "https://elrumo.github.io/css_clock/index.html",
          idProject: "projectImageClock",
          img: require("@/assets/images/clock.jpg"),
          tagOne: "#Front_End",
          tagTwo: "#Visual_Design",
          title: "CSS Clock",
          description: "A simple CSS and pure JS clock.",
          linkType: "url",
        },

        gemma:{
          id: "gemma",
          onMouseOver: "onHover('projectImagegemma')",
          onMouseOut: "onMouseout('projectImagegemma')",
          path: "https://elrumo.github.io/gemma/",
          idProject: "projectImagespeaker",
          img: require("@/assets/images/gemma.gif"),
          tagOne: "#Graphic_Design",
          tagTwo: "#Fron_End",
          title: "Gemma Collins!",
          description: "A joke on the British reality star Gemma Collins, in collaboration with [Matt Putman](mattputman.com) and Alice Edwards",
          linkType: "url",
        },

        guessGame:{
          id: "guessGame",
          onMouseOver: "onHover('projectImageGuessGame')",
          onMouseOut: "onMouseout('projectImageGuessGame')",
          path: "https://elrumo.github.io/guess_the_number/",
          idProject: "projectImageGuessGame",
          img: require("@/assets/images/number_game.jpg"),
          tagOne: "#UI_Design",
          tagTwo: "#Front_End",
          title: "Guess The Number Game",
          description: "A vanilla JS guess the number game",
          linkType: "url",
        },

      },
    };
  },
  components: {
    MoreProjects,
    GoBack,
    Project
  },
}
</script>

<style lang="css">
  /* @import url("Playground.css"); */
</style>
