<template>
    <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
        <img class="projectPageImage breakPageImage" :src="pageContent.img" alt="">
        <h4 v-if="pageContent.footNote" class="footNote">
            <VueMarkdown>
                {{ pageContent.imgFooter }}
            </VueMarkdown>
        </h4>
    </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown'

    export default {
        name:"Images",
        props:{
            pageContent: {}
        },
        components:{
            VueMarkdown
        },
        created(){
            // console.log(this)
        }

    }
</script>

<style>

</style>