<template lang="html">
  <div class="wrapper pageWrapper">
    <div class="projectPageWrapper">
    <!-- The Intro -->
        <section id="intro">
          <GoBack/>
          <h1>WebBites.io</h1>
          <h2 class="subTitle">Bookmarking, made accessible.</h2>
          <h3 class="projectPageDescription">
            Many bookmarking tools are so text-heavy that they cause accessibility issues to users with visual impairments or learning disabilities such as Dyslexia. As someone with Dyslexia, I understood that first hand, so I set out to build the most accessible bookmarking service out there.
           
            <br/>
            <br/>

            If you'd rather try WebBites for yourself, head over to
            <a target="_blank" href="https://webbites.io/">webbites.io</a>
          </h3>
          
          <br/>
          <br/>
          <br/>
            <!-- <iframe class="iframeWeb" src="https://eliasruiz.com/digital_sweetness/" width="100%" height="700px"></iframe> -->

          <div class="projectPageImage breakPageImageWrapper imageBottomPading">
            <img class="projectPageImage breakPageImage" src="@/assets/images/digital_sweentess_project_page/webbites-banner.jpg" alt="">
            <h4 class="footNote"><b>Fig.1.</b> WebBites.io in its current and ongoing iteration</h4>
          </div>
        </section>

    <!-- The Prototype -->
        <section class="projectTextWrapper endOfSection">
            <h2 class="projectTextTile">Protoype it</h2>
            <h3 class="projectPageDescription">
             The very first prototype, designed and built-in one-weekend using Vue.js and FireBase, served as a very simple MVP and starting point.
              <br>
              <br>
             The concept and interaction of the early site was simple: the user inputs a URL, and a screenshot of the site gets added and displayed back to the user. Having an accessible interface was key, so from that point, I started using Spectrum, Adobe's design system and JS framework, which has a strong focus on accessibility and is WCAG 2.1 compliant.
            </h3>

            <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
              <img class="projectPageImage breakPageImage" src="@/assets/images/digital_sweentess_project_page/digital_sweentess_concept.png" alt="">
              <h4 class="footNote"><b>Fig.2.</b> Early concept screen and working prototype and progressive web app</h4>
            </div>
        </section>

        <section class="projectTextWrapper endOfSection">
          <h2 class="projectTextTile"> All about the Feedback</h2>
          <h3 class="projectPageDescription">
            Since the early stages in 2018, until the first beta was launched in 2020, users have been an integral part of the project. During all stages of the process, I have constantly been reaching out to potential users for feedback as I was designing and developing WebBites.
            <br>
            <br>
            The feedback was gathered from posting on Facebook groups, asking on <a target="_blank" href="https://www.reddit.com/r/vuejs/comments/inwlte/i_built_a_new_bookmarking_service_with_vue/"> Reddit </a> subreddits, to online surveys on the landing page. It was during the user feedback sessions that I understood the problem and the lack of bookmarking tools made with accessibility in mind. 

          </h3>
          <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            <img src="@/assets/images/digital_sweentess_project_page/reddit.9c558a5c.png" alt="" class="projectPageImage breakPageImage">
            <h4 class="footNote">
              <b>Fig.3.</b> Feedback from Reddit
            </h4>
            </div>
        </section>

    <!-- The Data -->
        <section class="projectTextWrapper endOfSection">
          <h2 class="projectTextTile"> The Data</h2>
          <h3 class="projectPageDescription">
            The simple question I posted on the <a target="_blank" href="https://www.facebook.com/groups/159669727989988/"> Innovation Hackers FaceBook </a> group generated a considerable amount of interest and insights. The comments varied substantially, from users stating that they don't see any value in hoarding digital content to others using multiple services like Pinterest, Pocket, Google Keep and browser bookmarks.
            <br>
            <br>
            Independently of their tool used, two main patterns repeatedly appeared:
          </h3>

          <div class="insightsGridWrapper sourceSans two-column-grid">
            <ul class="insightsWrapper">
              <div class="bulletNumbers">·</div> 
              <li>
                Users save data across multiple services, making it hard and annoying to find and retrieve.
              </li>
            </ul>
            <ul class="insightsWrapper">
              <div class="bulletNumbers">·</div> 
              <li>
                Bookmarking tools quickly become an uncontrollable mess and information is hard to find.
              </li>
            </ul>
          </div>

          <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            <img class="projectPageImage breakPageImage" src="@/assets/images/digital_sweentess_project_page/ds_fb_comments.png" alt="">
            <h4 class="footNote"><b>Fig.4.</b> A sample of some of the answers given</h4>
          </div>

        </section>

    <!-- The Insisghts -->
        <section class="projectTextWrapper endOfSection">
          <h2 class="projectTextTile">The Insights</h2>
          <h3 class="projectPageDescription listText">
            The information extracted from the research was synthesised to a few key insights:
            </h3>
            <div class="insightsGridWrapper p-t-50 sourceSans two-column-grid p-t-0">
              
              <ul class="insightsWrapper">
                <div class="bulletNumbers">1</div>
                <li>
                  Users save data across multiple services, making it hard and annoying to find and retreive.
                </li>
              </ul>
              
              <ul class="insightsWrapper">
                <div class="bulletNumbers">2</div> 
                <li>
                  Efectively managing bookmarks is too time-consuming and labour intensive, users eventually give up.
                </li>
              </ul>

              <ul class="insightsWrapper">
                  <div class="bulletNumbers">3</div>
                  <li>
                    Many users reported having built their system around note-taking apps, mostly due to their simplicity and ease of use.
                  </li>
              </ul>
              
              <ul class="insightsWrapper">
                <div class="bulletNumbers">4</div>
                <li>
                  Speed is massively important, hence why some rely on the convenience of instant messaging to store URLs.
                </li>
              </ul>

              <ul class="insightsWrapper">
                <div class="bulletNumbers">5</div>
                <li>
                  Users are saving URLs but are not going back to visit the saved sites, losing them in oblivion never to be seen again.
                </li>
              </ul>
              
              <ul class="insightsWrapper">
                <div class="bulletNumbers">6</div>
                <li>
                  There is a lack of an external and intrensic motivation to go back to the tool with saves sites.
                </li>
              </ul>

            </div>
            <br>
        </section>

    <!-- The Approach -->
        <section class="projectTextWrapper endOfSection">
          <h2 class="projectTextTile">The Approach</h2>
          <h3 class="projectPageDescription">
             Instead of waiting until the product is complete to start iterating, the democratisation of the internet has enabled a leaner and more empathetic type of product design. The MVP was given to a small portion of users to reflect on their feedback and iterate accordingly.
              <br>
              <br>
              The implementation of an iterative development process resulted in the adoption of features and design choices that have been built along with the end-user. The design of the service is still under development and may be subject to future changes.
          </h3>

          <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            <div class="twoImagesGrid">
              <video class="projectPageImage breakPageImage img" src="@/assets/images/digital_sweentess_project_page/video/wireframe/grid_list.mp4" autoplay muted loop playsinline poster=""></video>
              <video class="projectPageImage breakPageImage img" src="@/assets/images/digital_sweentess_project_page/video/wireframe/side_bar.mp4" autoplay muted loop playsinline poster=""></video>
            </div>
            <h4 class="footNote"><b>Fig.5.</b> Interactive wireframes, built on Principe.</h4>
          </div>

        </section>

    <!-- The development -->
        <section class="projectTextWrapper endOfSection">
          <h2 class="projectTextTile">The Development</h2>
          <h3 class="projectPageDescription">
            The service is currently under development and a series of extra features such as a Chrome plugin and mobile offline access are all also planned for the future. In the meantime, sign up for the open beta at
            <a href="https://webbites.io/">https://webbites.io</a>
            <br>
            <br>
            <br>
            Full tech and design stack: 
            <ul class="insightsGridWrapper sourceSans three-column-grid">
              <li>
                <a href="https://www.figma.com/" target="_blank" class="m-b-0 f-w-600"> Figma </a>
                <p class="m-0 f-s-16 "> UI design, mockups, wireframes, branding </p>
              </li>
              
              <li>
                <a href="https://opensource.adobe.com/coral-spectrum/dist/documentation/" target="_blank" class="m-b-0 f-w-600"> Adobe Coral Spectrum </a>
                <p class="m-0 f-s-16 "> Adobe's design system, UI components </p>
              </li>
              
              <li>
                <a href="https://vuex.vuejs.org" target="_blank" class="m-b-0 f-w-600"> Vue + Vuex </a>
                <p class="m-0 f-s-16 "> Framework to build the UI and manage the data </p>
              </li>
              
              <li>
                <a href="https://firebase.google.com" target="_blank" class="m-b-0 f-w-600"> Goole FireBase </a>
                <p class="m-0 f-s-16 "> Serverless databse, cloud functions &amp; authentification. </p>
              </li>
              
              <li>
                <a href="https://quasar.dev" target="_blank" class="m-b-0 f-w-600"> Quasar </a>
                <p class="m-0 f-s-16 "> Top level framework to manage builds. </p>
              </li>
              
              <li>
                <a href="https://quasar.dev/quasar-cli/developing-electron-apps/introduction" target="_blank" class="m-b-0 f-w-600"> Electron </a>
                <p class="m-0 f-s-16 "> Wrapper to build macOS app. </p>
              </li>
            </ul>

            <br>
          </h3>

          <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            <a target="_blank" href="https://webbites.io/" style="">
              <img src="@/assets/images/digital_sweentess_project_page/webbites_bg_blue.64867f78.jpg" autoplay="autoplay" muted="muted" loop="loop" playsinline="" poster="" class="projectPageImage breakPageImage img">
              <h4 class="footNote">
                <b>Fig.6.</b> WebBites Main View
              </h4>
            </a>
          </div>

        </section>
      </div>
    <MoreProjects :projects="moreProjects"/>
    </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  export default {
    name: 'DigitalSweetness',
    data(){
      return{
        moreProjects:{
          previous: {
            title: "My Playground",
            path: "/playground"
          },
          next: {
            title: "IBM",
            path: "/ibm"
          },
        }
      };
    },
    components: {
      MoreProjects,
      GoBack
    },
  }
</script>

<style lang="css">
  /* @import url("DigitalSweetness.css"); */
</style>
