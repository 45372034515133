<template lang="html">
  <div class="projectPageWrapper wrapper">
    <!-- The Intro -->
    <section class="endOfSection endOfPage">
      <GoBack/>
      <h1>CocaCola</h1>
      <h3 class="projectPageDescription">
        In 2015 I took part in the national marketing campaign by CocaCola Spain, the aim was to reach a younger audience through the power of social media and other seven creative individuals like myself.
        <br>
        <br>
        As part of the campaign, I was asked of CocaCola to produce two pieces of content a month for 12 months, in the form of video and a photo; these were then promoted on their official social media channels as well as on a YouTube ad.</h3>
      <iframe class="iframeVideo" src="https://player.vimeo.com/video/151418553?title=0&byline=0&portrait=0" width="740" height="460" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </section>

    <!-- More Projects -->
    <MoreProjects :projects="projects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  export default {
    name: 'Cocacola',
    data(){
      return{
        projects:{
          previous:{
            title: "My Playground",
            path: "/playground"
          },
          next:{
            title: "Digital Sweetness",
            path: "/digitalsweetness"
          }
        }
      };
    },
    components: {
      MoreProjects,
      GoBack
    },
  }
</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
