<template lang="html">
  <div class="projectPageWrapper wrapper">
        
        <Intro :pageContent="pageContent.intro"/>

        <TextTwoImg :pageContent="pageContent.module_1"/>

        <TextTwoImg :pageContent="pageContent.module_2"/>

        <TextTwoImg :pageContent="pageContent.module_3"/>

        <TextTwoImg :pageContent="pageContent.module_4"/>

        <TextTwoImg :pageContent="pageContent.module_6"/>

        <TextTwoImg :pageContent="pageContent.module_5"/>
        
        <TextTwoImg :pageContent="pageContent.module_7"/>

        <TextTwoImg :pageContent="pageContent.module_8"/>



        <!-- <TextTwoImg :pageContent="pageContent.module_4"/> -->

    <!-- More Projects -->
    <MoreProjects :projects="projects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  import Intro from "@/components/Intro.vue"
  import TextOneImg from "@/components/TextOneImg.vue"
  import TextTwoImg from "@/components/TextTwoImg.vue"
  import Insights from "@/components/Insights.vue"
  import Embeded from "@/components/Embeded.vue"

  export default {
    name: 'OtherDesign',
    components: {
      MoreProjects,
      GoBack,
      Intro,
      TextOneImg,
      Insights,
      TextTwoImg,
      Embeded
    },
    data(){
      return{
        projects:{
          previous:{
            title: "Nightmare Valley",
            path: "/nightmare_valley"
          },
          next:{
            title: "IBM",
            path: "/ibm"
          }
        },
        pageContent:{
          intro: {
            title: "Design Bites",

            subTitle: "Smaller design projects I've worked on",

            description: "During my time as a designer I've worked on a series of jobs that have resulted in smaller, but yet enjoyable and important projects. These are some of my favouite design bites.",
            
            img: "https://elrumo.github.io/css_clock/index.html",

            type: "iframe",

          },
          module_1:{
            title:"Moving Icons",

            par1: "During my time at IBM, besides all the tasks mentioned on the project page, I was also in charge of designing all of our presentations. To add dynamism and make the slides more interesting I created some animated icons.",
            images: {
                img1: {
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/draw.gif"),
                  css: "",
                },
                img2:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/parent-app.gif"),
                  css: "",
                },
                img3:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/parent-trust.gif"),
                  css: "",
                },
                img4:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/scale.gif"),
                  css: "",
                },
                img5:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/shake.gif"),
                },
                img6:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/slot.gif"),
                  css: "",
                },
                img7:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/speech.gif"),
                  css: "",
                },
                img8:{
                  type: "image",
                  file: require("@/assets/images/designBites/moving_icons/tap.gif"),
                  css: "",
                },
              },
            
            gridNo: "grid-template-columns: repeat(4, 1fr); grid-row-gap: 50px;",
  
            inline_css: "image-rendering: pixelated; height: 80px; width: auto; position: relative; left: 50%; transform: translate3d(-50%,0,0); padding-bottom: 40px; padding-top: 10px;",

          },

          module_2:{
            title:"Munich in Vectors",

            par1: "As part of an internal project at the design agency SMAL GmbH, where I worked as an intern for 12 months, I was asked to create a vectorised miniature version of its hometown, Munich.",

            images: {
              img1: {
                type: "image big",
                file: require("@/assets/images/designBites/munich/munich_vector.png"),
                css: "",
              },
            },
            
            gridNo: "grid-template-columns: repeat(8, 1fr);",
            
            inline_css: "height: auto; width: 90%; position: relative; left: 50%; transform: translate3d(-50%,0,0); padding-bottom: 0px; padding-top: 0px;",

            inline_css_big: "margin-bottom: 50px;",

          },

          module_3:{
            title:"Book Cover",

            par1: "'Robert y Leo' is a novel written by the author who goes by the name Reginah George for Selecta Spain, Penguin Random House. It's the first book in a series of three other books I have also been commissioned to design.",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/designBites/books/marcos_book_1.png"),
                css: "",
              },
              img2: {
                type: "image",
                file: require("@/assets/images/designBites/books/marcos_book_2.png"),
                css: "",
              },
            },
            
            gridNo: "grid-template-columns: repeat(2, 1fr);",
          },

          module_4:{
            title:"Study Fast",

            par1: "Study Fast is a company that focuses on teaching speed reading for everyone, it empowers 7,000+ people in over 140 countries to learn anything faster. To increase its social media positioning I re-designed most of their YouTube thumbnail videos.",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_1.jpg"),
              },
              img2: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_2.jpg"),
              },
              img3: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_3.jpg"),
              },
              img4: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_4.jpg"),
              },
              img5: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_5.jpg"),
              },
              img6: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_6.jpg"),
              },
              img7: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_7.jpg"),
              },
              img8: {
                type: "image",
                file: require("@/assets/images/designBites/studyFast/studyFast_8.jpg"),
              },
              imgIntro: {
                type: "image big",
                file: require("@/assets/images/designBites/studyFast/studyFast_intro.png"),
              },
            },
            
            gridNo: "grid-template-columns: repeat(4, 1fr);",
          },

          module_5:{
            title:"Drawings",

            par1: "From time to time I enjoy sketching on my iPad, from concept art to more classic industrial design sketching.",

            images: {
              img3: {
                type: "video",
                file: require("@/assets/images/designBites/drawings/speaker_animation.mp4"),
                css: "",
              },
              img2: {
                type: "image",
                file: require("@/assets/images/designBites/drawings/game_1.png"),
                css: "",
              },
              img1: {
                type: "image",
                file: require("@/assets/images/designBites/drawings/womanRed.jpg"),
                // file: require("@/assets/images/designBites/drawings/womanRed.mp4"),
                css: "",
              },
            },
            inline_css: "image-rendering: pixelated;",
            gridNo: "grid-template-columns: repeat(1, 1fr);",
          },

          module_7:{
            title:"CSS Clock",

            par1: "I believe coding is a very useful tool for a desinger, so as I was learning CSS and JS I designed and built [this clock](https://elrumo.github.io/css_clock/index.html) as an experiment.",

            images: {
              img1: {
                type: "iframe",
                  file: "https://elrumo.github.io/css_clock/index.html",
                  css: "",
                },
              },
            gridNo: "grid-template-columns: repeat(1, 1fr);",
          },

          module_8:{
            title:"CSS Planterary System",

            par1: "Another code experiment, this time I was exploring the animation features of CSS by desinging a fake planetary system. This experiment is 100% CSS and contains no JS.",

            images: {
              img1: {
                type: "iframe",
                  file: "https://elrumo.github.io/planteray-system/",
                  css: "",
                },
              },
            gridNo: "grid-template-columns: repeat(1, 1fr);",
          },

          module_6:{
            title:"Typography",

            par1: "Typography has so many technical terms that it can at times be a bit complicated to remember and understand, to fix that problem I wrote a series of [Medium article](https://medium.com/@elrumo/the-typography-cheat-sheet-part-1-c2a0b50ee34d) expaling some of those terms with illustrated examples.",

            images: {
              img2: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_1.jpg")
              },
              img3: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_2.jpg")
              },
              img4: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_3.jpg")
              },
              img5: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_4.jpg")
              },
              img6: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_5.jpg")
              },
              img7: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_6.jpg")
              },
              img8: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_7.jpg")
              },
              img9: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_8.jpg")
              },
              img10: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_9.jpg")
              },
              img11: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_10.jpg")
              },
              img12: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_11.jpg")
              },
              img13: {
                type: "image",
                file: require("@/assets/images/designBites/typography/typography_12.jpg")
              },
              imgIntro: {
                type: "image big",
                file: require("@/assets/images/designBites/typography/typography_intro.jpg"),
              },
            },
            
            gridNo: "grid-template-columns: repeat(4, 1fr);",
          },


        }
      }
    }
  }

</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
