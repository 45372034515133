<template>
  <section class="projectTextWrapper endOfSection">
        <h2 class="projectTextTile"> 
            <VueMarkdown> 
                    {{ pageContent.title }}
            </VueMarkdown> 
        </h2>
        <h3 class="projectPageDescription">
            <vue-markdown> 
                {{ pageContent.par1 }}
            </vue-markdown>
        </h3>

        <div v-if="pageContent.img != ''" class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            
            <img v-if="pageContent.type === 'image'" :style="pageContent.inline_css" class="projectPageImage breakPageImage img" :src="pageContent.img">

            <video v-else-if="pageContent.type === 'video'" :style="pageContent.inline_css" class="projectPageImage breakPageImage img" :src="pageContent.img" autoplay controls="true" muted loop playsinline poster=""></video>
            
            <h4 class="footNote">
                <VueMarkdown> 
                    {{ pageContent.imgFooter }}
                </VueMarkdown>
            </h4>
        </div>

    </section>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: "TextOneImg",
    props:{
        pageContent:{}
    },
    components:{
        VueMarkdown
    }

}
</script>

<style>

</style>