<template lang="html">
  <div class="wrapper pageWrapper">
    <div class="projectPageWrapper">
      <!-- The Intro -->
      <section>
        <GoBack/>
        <h1>Coding For Kids</h1>
        <h2 class="subTitle">Programming, with no screen</h2>
        <h3 class="projectPageDescription">
          Ongoing project, part of my final year of my undergraduate degree of Industrial Design & Technology at Loughborough University.</h3>
        <h4 class="quote">"When you learn computing, you're thinking about thinking."</h4>
        <h3 class="projectPageDescription">
          In 2014 the UK made history by becoming the first country in the world to officially incorporate computer science into the primary and secondary education curriculum. This radical change brought some problems with it, schools were unprepared. The goal with this project is to introduce computing principles, such as boolean logic and algorithms to Key Stage 1 students, ages 5 to 6, without the need for screens.
        </h3>

        <div class="projectImage> breakPageImageWrapper imageTopPading" style="padding-top: 10px;">
          <div class="">
            <img class="projectPageImage breakPageImage" src="@/assets/images/coding/code2.jpg" alt="">
            <h4 class="footNote"><b>Fig.1.</b> Final product design and branding</h4>
            <div class="twoImagesGrid breakPageImageWrapper">
              <div class="">
                <div class="projectPageImageMask">
                  <img class="projectPageImage breakPageImage" src="@/assets/images/coding/prototype.jpg" alt="">
                </div>
                <h4 class="footNote"><b>Fig.2.</b> An early 3D printed working prototype used for user testing</h4>
              </div>
              <div class="">
                <img class="projectPageImage breakPageImage" src="@/assets/images/coding/prototype2.jpg" alt="">
                <h4 class="footNote"><b>Fig.3.</b> Experience prototyping</h4>
              </div>
              <br>
            </div>
          </div>
          <br>
        </div>

      </section>

      <!-- The Need -->
      <section class="projectTextWrapper">
        <h2 class="projectTextTile"> The Need</h2>
        <h3 class="projectPageDescription">
          This sudden change increased the demand for experienced and qualified teachers; a demand that unfortunately, the country is still struggling to satisfy, especially in the early stages of education.
          <br>
          <br>
          The content taught at Key Stage 1, as seen on the national curriculum published by the British Government, is very brief and broad, leaving schools and teachers lost. Presenting an opportunity to improve existing methods and practices.
        </h3>

        <div class="projectPageImage breakPageImageWrapper imageTopPading imageBottomPading">
            <img class="projectPageImage breakPageImage" src="@/assets/images/coding/ks1_curriculum.jpg" alt="">
            <h4 class="footNote"><b>Fig.2.</b> <a target="top" href="https://www.researchgate.net/publication/242383829_Algoblock_a_tangible_programming_language_a_tool_for_collaborative_learning">The official national curriculum,</a> page 179.</h4>
          <br>
        </div>

      </section>

      <!-- The Pioners -->
      <section class="projectTextWrapper endOfSection imageBottomPading">
        <h2 class="projectTextTile"> The Pioners</h2>
        <h3 class="projectPageDescription">
          Since the conception of modern computer programming, research on how to democratise this technology and teach it to the future generation has been abundant. The <a target="_blank" href="http://el.media.mit.edu/logo-foundation/what_is_logo/logo_and_learning.html">programming language LOGO,</a> which served as an inspiration for this project, is one of the earliest examples of this. The language is simple enough that even primary school children can use it, all while teaching the fundamentals of programming.
          <br>
          <br>
          Through a series of commands children move a turtle around the screen to generate geometrical shapes, some of which can be rather complex and "trippy". The code to draw a square would look something like this:
          </h3>
          <h5 class="codeSnippet">
            forward 50
            <br>
            left 90
            <br>
            forward 50
            <br>
            left 90
            <br>
            forward 50
            <br>
            left 90
            <br>
            forward 50
          </h5>
        <h3 class="projectPageDescription">
          Other pioneers in the 80s looked at teaching those principles by exploring a more tangible approach; instead of writing code using a screen and a keyboard, programming functions and commands are represented by physical objects and blocks, enabling toddlers that might not read or write yet, to program computers.
        </h3>


        <div class="twoImagesGrid breakPageImageWrapper imageTopPading ">
          <div class="">
            <img class="projectPageImage breakPageImage" src="@/assets/images/coding/star.png" alt="">
            <h4 class="footNote"><b>Fig.3.</b> The LOGO language in use, courtsey of <a target="top" href="http://www.sydlexia.com/logo.htm">sydlexia</a></h4>
          </div>
          <div class="">
            <img class="projectPageImage breakPageImage" src="@/assets/images/coding/algoblock.jpg" alt="">
            <h4 class="footNote"><b>Fig.4.</b> The <a target="top" href="https://www.researchgate.net/publication/242383829_Algoblock_a_tangible_programming_language_a_tool_for_collaborative_learning">Algoblock</a> research projcet </h4>
          </div>
          <br>
        </div>

      </section>


      <TextTwoImg :pageContent="pageContent.module_1"/>


      <!-- The Market -->
      <section class="projectTextWrapper endOfSection">
        <h2 class="projectTextTile"> The Market</h2>
        <h3 class="projectPageDescription">
          The market is already filled with great educational products but what led me to carry on with this project, however, was a simple question I asked myself. If there so many products out there, why are not more schools using them? The reason was simple and it presnted itself after a SWOT analysis was carried out.
          <br>
          <br>
          Most existing products are compromising in certain key aspects. There is not a balance between ease of use, cost and expandability. Some are inexpensive and easy to use but provide no way to expand as the child grows, others encourage creativity but are too complex while the rest are too costly to be purchased by public schools.
        </h3>

        <h3 class="projectPageDescription listText">
          The information extracted from the research has been synthesised to the following key insights:
          </h3>
        <br>
        <div class="insightsGridWrapper sourceSans">
          <ul class="insightsWrapper">
              <div class="bulletNumbers">1</div> <li>
                Most easy to use products tend to be too basic to offer a valuable learning experience
              </li>
            </ul>

          <ul class="insightsWrapper">
              <div class="bulletNumbers">2</div> <li>
                Advanced and expadible products require a display to be use, making them less suitable for young KS1 students.
              </li>
            </ul>

          <ul class="insightsWrapper">
              <div class="bulletNumbers">3</div><li>
                Most current offerings do not take the teacher into consideration, it is crucial the teachers has an understanding of the product as well.
              </li>
            </ul>

          <ul class="insightsWrapper">
              <div class="bulletNumbers">4</div><li>
                Only very few existing solutions grow with the child and the ones that do are too complex for younger children to understand.
              </li>
            </ul>

          <ul class="insightsWrapper">
              <div class="bulletNumbers">5</div><li>
                Most solutions do not focus on child to child physical interaction.
              </li>
            </ul>

          <ul class="insightsWrapper">
            <div class="bulletNumbers">6</div><li>
              The most feature rich prducts are too costly to be purchased by public schools
            </li>
          </ul>
          </div>

        <TextTwoImg :pageContent="pageContent.module_2"/>

        <!-- <div class=" breakPageImageWrapper imageTopPading">
          <div class="twoImagesGrid">
            <div class="">
              <img class="projectPageImage breakPageImage" src="@/assets/images/coding/swot/swot1.jpg" alt="">
            </div>
            <div class="">
              <img class="projectPageImage breakPageImage" src="@/assets/images/coding/swot/swot2.jpg" alt="">
            </div>
            <div class="">
              <img class="projectPageImage breakPageImage" src="@/assets/images/coding/swot/swot3.jpg" alt="">
            </div>
            <div class="">
              <img class="projectPageImage breakPageImage" src="@/assets/images/coding/swot/swot4.jpg" alt="">
            </div>
          </div>
          <h4 class="footNote"><b>Fig.5.</b>The SWOT analysis carried, minus the "thread".</h4>
        </div> -->

        <br>
        <br>
        <br>
      </section>

      <section class="underConstWrapperProject">
        <h1>/Under Construction</h1>
        <h2>Check back again soon</h2>
      </section>

    </div>
    <MoreProjects :projects="projects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  import TextTwoImg from "@/components/TextTwoImg.vue"

  export default {
    name: 'CodingKids',
    data(){
      return{
        projects:{
          previous:{
            title: "IBM",
            path: "/ibm"
          },
          next:{
            title: "China",
            path: "/china"
          }
        },
        pageContent:{
          module_1:{
            title:"The Booklet",

            par1: "Learning is a wholistic experience that requries multiple parties to collaborate, by having an introductory booklet I gave teachers and paretns the chance to get started with their kids in no time. To view the full booklet click [here](https://1drv.ms/b/s!AqxJTUkAbdHrl4BAbdMlNftdGTL-Pw?e=AqWCyB).",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/coding/onboarding_1.jpg"),
                css: "",
              },
              img2: {
                type: "image",
                file: require("@/assets/images/coding/onboarding_2.jpg"),
                css: "",
              },
            },
            
            gridNo: "grid-template-columns: repeat(2, 1fr);",
            
            inline_css: "height: auto; width: 90%; position: relative; left: 50%; transform: translate3d(-50%,0,0); padding-bottom: 0px; padding-top: 0px;",
          },

          module_2:{
            title:"",

            par1: "",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/coding/swot/swot1.jpg"),
                css: "",
              },
              img2: {
                type: "image",
                file: require("@/assets/images/coding/swot/swot2.jpg"),
                css: "",
              },
              img3: {
                type: "image",
                file: require("@/assets/images/coding/swot/swot3.jpg"),
                css: "",
              },
              img4: {
                type: "image",
                file: require("@/assets/images/coding/swot/swot4.jpg"),
                css: "",
              },
            },
            
            gridNo: "grid-template-columns: repeat(2, 1fr); grid-row-gap: 0px; margin-top: -35px; ",
            
            inline_css: "height: auto; width: 100%; position: relative; left: 50%; transform: translate3d(-50%,0,0); padding-bottom: 0px; padding-top: 0px;",
          }
        }
      };
    },
    components: {
      MoreProjects,
      GoBack,
      TextTwoImg
    },
  }
</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
