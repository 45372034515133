<template lang="html">
  <div class="projectPageWrapper wrapper">
    <!-- The Intro -->
    <section class="endOfSection endOfPage">
      <GoBack/>
      <h1>macOS icons</h1>
      <h3 class="projectPageDescription p-b-30">
        After seeing the new design language for macOS Big Sur, I started designing some icons for third party apps with the new style, and seeing that I wasn't the only one I designed and built an open source platform to let others design and submit their icons icons, all in one place.
        <br>
        <br>
        The website, <a target="_blank" href="https://macosicons.com/" style="color: #FFF031"> macOSicons.com</a>, has over 4.5K user submited icon, 40K+ active monthly users and 3.2K stars on GitHub.
      </h3>

      <a target="_blank" href="https://macosicons.com/">
        <Images v-for="img in imgs" :key="img.imgFooter" :pageContent="img"/>
      </a>
      
      <!-- projectPageImage breakPageImage -->
    </section>

    <!-- More Projects -->
    <MoreProjects :projects="moreProjects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  import Images from "@/components/Images.vue"

  export default {
    name: 'macOSicons',
    data(){
      return{
        moreProjects:{
          previous: {
            title: "China, seen by my lens",
            path: "/china"
          },
          next: {
            title: "Nightmare Valley",
            path: "/Nightmare_Valley"
          }
        },
        imgs:{
          img1:{ 
            img: require("@/assets/images/macOSicons/Safari.png")
          },
          img2:{ 
            img: require("@/assets/images/macOSicons/macOSicons_3.jpg")
          },
        },
      };
    },
    components: {
      MoreProjects,
      GoBack,
      Images
    },
  }
</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
