<template>
  <section id="intro">
        <GoBack whereTo="whereTo"/>
        <h1>{{ pageContent.title }}</h1>
        <h2 class="subTitle">
            <VueMarkdown class="subTitle">
                {{ pageContent.subTitle }}
            </VueMarkdown>
        </h2>
        <h3 class="projectPageDescription">
            <VueMarkdown>
                {{ pageContent.description }}
            </VueMarkdown>
        </h3>

        <div v-if="pageContent.type != 'none'" class="projectPageImage breakPageImageWrapper imageBottomPading">
            <img v-if="pageContent.type === 'image'" class="projectPageImage breakPageImage" :src="pageContent.img" alt="">
            <video v-else-if="pageContent.type === 'video'" class="projectPageImage breakPageImage img" :src="pageContent.img" autoplay muted loop playsinline controls="true" poster=""></video>
            <iframe v-else-if="pageContent.type === 'iframe'" :src="pageContent.img" frameborder="0"></iframe>
            <h4 class="footNote"><VueMarkdown> {{ pageContent.imgFooter }}</VueMarkdown></h4>
        </div>

        <div v-else-if="pageContent.type === 'none'" class="noImageIntro">

        </div>
        
        
    </section>
</template>

<script>
import GoBack from "@/components/GoBack.vue"
import VueMarkdown from 'vue-markdown'

export default {
    name:"Intro",
    props:{
        pageContent: {}
    },
    components:{
        GoBack,
        VueMarkdown
    }

}
</script>

<style>

</style>