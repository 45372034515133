import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home/Home.vue'
import DigitalSweetness from '@/views/DigitalSweetness.vue'
import CodingKids from '@/views/CodingKids.vue'
import Cocacola from '@/views/Cocacola.vue'
import Playground from '@/views/Playground.vue'
import Pride from '@/views/Pride.vue'
import IBM from '@/views/IBM.vue'
import China from '@/views/China/China.vue'
import Nightmare_Valley_Old from '@/views/Nightmare_Valley_Old.vue'
import Nightmare_Valley from '@/views/Nightmare_Valley.vue'
import macOSicons from '@/views/macOSicons.vue'
import DesignBites from '@/views/DesignBites.vue'
// import Signup from '@/views/AuthPage/Signup.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/cocacola',
      name: 'cocacola',
      component: Cocacola
    },
    {
      path: '/webbites',
      name: 'digitalsweetness',
      component: DigitalSweetness
    },
    {
      path: '/playground',
      name: 'playground',
      component: Playground
    },
    {
      path: '/coding',
      name: 'coding',
      component: CodingKids
    },
    {
      path: '/pride',
      name: 'pride',
      component: Pride
    },
    {
      path: '/ibm',
      name: 'ibm',
      component: IBM
    },
    {
      path: '/china',
      name: 'china',
      component: China
    },
    {
      path: '/nightmare_valley_old',
      name: 'nightmare_valley_old',
      component: Nightmare_Valley_Old,
    },
    {
      path: '/nightmare_valley',
      name: 'nightmare_valley',
      component: Nightmare_Valley,
      props: {
        default: true,
      }
    },
    {
      path: '/macOSicons',
      name: 'macOSicons',
      component: macOSicons
    },
    {
      path: '/design_bites',
      name: 'design_bites',
      component: DesignBites
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})
