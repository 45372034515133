<template>
  <section class="projectTextWrapper">

        <iframe class="iframeVideo" allow="autoplay; fullscreen" :src="pageContent.videoUrl" :width="740" height="" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

        <!-- <iframe src="https://player.vimeo.com/video/382834171" width="640" height="401" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
        
    </section>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: "TextTwoImg",
    props:{
        pageContent:{}
    },
    components:{
        VueMarkdown
        // GoBack,
        // MoreProjects
    }

}
</script>

<style>

</style>