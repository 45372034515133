<template>
  <div id="about" class="aboutGrid">
        <div class="aboutWrapper">
          <div class="about">
            <h1>Hola,</h1>
            <h3>
             <VueMarkdown> {{ about.description1 }} </VueMarkdown>
            </h3>
            <div class="socialWrapper">
              <div class="button-wrapper">
                  <a class="button" rel="noopener" target="_blank" href="http://bit.ly/elias_cv">View CV</a>
              </div>

              <a v-for="network in social" :key="network.url" rel="noopener" target="_blank" :href="network.url" style="">
                <img class="social-icon" :src="network.icon" alt="">
              </a>

            </div>
          </div>
        </div>

          <div class="aboutImage">
              <img class="yellowShadow" src="@/assets/images/about.jpg" alt="">
          </div>
    </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  export default {
      name: "About",
      props:{
          about: {}
      },
      components:{
        VueMarkdown
      },
      data(){
        return {
          social:{
            linkedin: {
              icon: require("@/assets/images/Social/linkedin.svg"),
              url: "https://www.linkedin.com/in/elrumo/",
            },
            github: {
              icon: require("@/assets/images/Social/github.svg"),
              url: "https://github.com/elrumo",
            },
            twitter: {
              icon: require("@/assets/images/Social/twitter.svg"),
              url: "https://www.twitter.com/elrumo/",
            },
            email: {
              icon: require("@/assets/images/Social/email.svg"),
              url: "mailto:elias.ruiz.monserrat@gmail.com",
            },
            // instagram: {
            //   icon: "",
            //   url: "",
            // },
          }
        }
      }

  }
</script>

<style>

</style>