<template lang="html">
  <div>

    <div class="projectPageWrapper wrapper">
      <Intro :pageContent="pageContent.intro"/>

      <TextOneImg :pageContent="pageContent.theBrief"/>

      <TextTwoImg :pageContent="pageContent.theApproach"/>

      <TextTwoImg :pageContent="pageContent.theInterviews"/>

      <TextTwoImg :pageContent="pageContent.theDevelopment"/>

      <Insights   :pageContent="pageContent.theProcess"/>

      <TextTwoImg :pageContent="pageContent.thePresentation"/>
    </div>

    <!-- More Projects -->
    <MoreProjects :projects="moreProjects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  import Intro from "@/components/Intro.vue"
  import TextOneImg from "@/components/TextOneImg.vue"
  import TextTwoImg from "@/components/TextTwoImg.vue"
  import Insights from "@/components/Insights.vue"

  export default {
    name: 'IBM',
    components: {
      MoreProjects,
      GoBack,
      Intro,
      TextOneImg,
      Insights,
      TextTwoImg,
    },
    data(){
      return{
        moreProjects:{
          previous: {
            title: "WebBites",
            path: "/webbites"
          },
          next: {
            title: "China",
            path: "/china"
          },
        },
        pageContent:{
          intro: {
            title: "IBM",

            subTitle: "Extreme Blue Internship",

            description: "[Extrme Blue](https://www.ibm.com/employment/extremeblue/) is an internship at IBM lasting 12 weeks, during which time, 15 other interns live and work together in teams of 4, one designer and three technical people, to address a real-world challenge confronting part of IBM's business. \n\n Between June 2019 and September 2019, I worked with three other Extreme Blue interns to ideate, test and develop a new product and service for Aardman Animations.",

            img: require("@/assets/images/IBM_Page/team1.jpg"),
            // img: require("@/assets/images/IBM_Page/IBM_Intro.jpg"),

            imgFooter: "**Fig.1.** Our team and two of our design mentors. [IBM Extreme Blue](https://www.ibm.com/employment/extremeblue/)",

            type: "image",

          },
          
          theBrief:{
            title:"The Brief",

            par1: " \n \n My team was briefed to build a new kind of streaming platform by an IBM client, the Oscar-winning studio [Aardman Animations](https://www.aardman.com/), the studio behind movies like Wallace & Gromit, Shaun The Sheep, Chicken Run and many others. \n\n ***Please note that due to the confidential nature of the project, most of the details have been omitted and I have limited myself to talk about the process.*** ",

            img: require("@/assets/images/IBM_Page/IBM_Aardman.jpg"),

            imgFooter: "**Fig.2.** [Aardman Animations](https://www.aardman.com/)",

            inline_css: " ",

            type: "image",
            

          },

          theApproach:{
            title:"The Approach",

            par1: "\n\nThe brief Aardman proposed was rather broad, which gave us the opportunity to **consider and explore areas most streaming services don’t**. \n \n This rare chance resulted in a service that goes beyond the screen and expands the experience into the physical world, all while creating a final product that takes into consideration the needs of many of the stakeholders involved and not just those of the individual viewer. \n\n However, the brief being so broad, we had to find an area of focus, which we did through a series of **design thinking workshops** with our client. This resulted in one idea we developed and iterated on for a few weeks until it was ready to be tested with real users.",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/IBM_Page/post-its.jpg")
              },
              img2:{
                type: "image",
                file: require("@/assets/images/IBM_Page/aardmanHQ.jpg")
              }
            },

            gridNo: "grid-template-columns: repeat(2, 1fr);",

            imgFooter: "**Fig.3.** **Left:** Design thinking session results. **Right:** Our first vist to Aardman HQ.",

            inline_css: " ",

            type: "image",

          },
          

          theInterviews:{
            title:"The Testing",

            par1: "The first few weeks were spent debating ideas, conducting primary and secondary research and talking directly to potential users. Based on the data gathered, we created two primary personas very early on, iterating on them continuously as the weeks went by, and we understood the end user better. \n\n This helped us build the first working prototype much faster, which allowed Aardman to bring in several families to conduct numerous user testing sessions we had designed and organised. These sessions resulted in a series of unexpected findings of the user's existing habits and how they interacted with our prototype.",

             images: {
              img1: {
                type: "image",
                file: require("@/assets/images/IBM_Page/userTesting1.jpg")
              },
              img2:{
                type: "image",
                file: require("@/assets/images/IBM_Page/userTesting2.jpg")
              }
            },

            gridNo: "grid-template-columns: repeat(2, 1fr);",

            imgFooter: "**Fig.4.** Photos of the user testing sessions. \n Faces and screen have been blurred for anonimity purposes.",

            figNo: "4",

            inline_css: " ",

            type: "image",

          },

          theDevelopment:{
            title:"The Development",

            par1: "As we were in charge of this project, we were free to use whatever tool we thought most appropriate for the job. We decided to go with Unity for a native app and Vue for a mobile Progressive Web App. The backend was built using IBM technologies and IBM Cloud. \n\n Beside the service and user experience design, I was in charge of designing both apps and building the mobile app on top of the JS framework Vue. In contrast, the Unity app was developed by the other three developers.",

            par2: "Paragraph 2",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/IBM_Page/vue.jpg")
              },
              img2:{
                type: "image",
                file: require("@/assets/images/IBM_Page/unity.jpg")
              }
            },

            gridNo: "grid-template-columns: repeat(2, 1fr);",

            imgFooter: "**Fig.5.** [Vue](https://vuejs.org) and [Unity](https://unity.com/), the tools we used.",

            inline_css: " ",

            type: "image",

          },

           theProcess:{
            title:"The Process",

            par1: "The 12 weeks we spent on this project, could be synthesised into this process:",

            insight1:"First meeting with Aardman, where the brief is introduced to us.",

            insight2:"We run a [Design Thinking](https://www.ibm.com/design/thinking/) workshop with the Aardman team to better understand their vision and client needs.",

            insight3:"The ideas from the workshop are explored with more detail as we conduct a series of interviews and carry on primary and secondary research.",

            insight4:"We decide what the MVP should be, design a low fidelity prototype, choose its core features based on our research assumptions and build it.",

            insight5:"We carry out a series of user testing sessions to test the service and gather feedback.",

            insight6:"We build a new prototype based on the feedback received, we test again, iterate its design, and develop the final version.",
          },

          thePresentation:{
            title:"The Presentation",

            par1: "The 12 weeks at IBM concluded with a trip to Cluj-Napoca, Romania, to present what we all had achieved over that time along with other Extreme Blue teams across Europe. \n \n A few days before that, our team and a couple of our mentors travelled to Bristol, where Aardman is based, to present and showcase our final product/service to their executive team and company founders.",

            par2: "Paragraph 2",
            
            media: "video",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/IBM_Page/presenting.jpg")
              },
              img2:{
                type: "video",
                file: require("@/assets/images/IBM_Page/romania_timelapse.mp4")
              }
            },

            gridNo: "grid-template-columns: repeat(2, 1fr);",

            imgFooter: "**Fig.6.** Me on stage presenting and a timelapse of Cluj-Napoca, Romania.",

            inline_css: " ",

            type: "image",

          },


        }
      };
    },
  }
</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
