<template lang="html">
  <div class="projectPageWrapper wrapper">
        
        <Intro :pageContent="pageContent.intro"/>

        <TextOneImg :pageContent="pageContent.module_1"/>

        <TextTwoImg :pageContent="pageContent.module_2"/>

        <TextTwoImg :pageContent="pageContent.module_3"/>

        <TextTwoImg :pageContent="pageContent.module_4"/>
        
        <TextTwoImg :pageContent="pageContent.module_5"/>
        
        <TextTwoImg :pageContent="pageContent.module_6"/>

        <!-- <TextTwoImg :pageContent="pageContent.module_4"/> -->

    <!-- More Projects -->
    <MoreProjects :projects="projects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  import Intro from "@/components/Intro.vue"
  import TextOneImg from "@/components/TextOneImg.vue"
  import TextTwoImg from "@/components/TextTwoImg.vue"
  import Insights from "@/components/Insights.vue"
  import Embeded from "@/components/Embeded.vue"

  export default {
    name: 'Nightmare_Valley',
    components: {
      MoreProjects,
      GoBack,
      Intro,
      TextOneImg,
      Insights,
      TextTwoImg,
      Embeded,

    },
    data(){
      return{
        projects:{
          previous:{
            title: "Digital Sweetness",
            path: "/digitalsweetness"
          },
          next:{
            title: "Coding for kids",
            path: "/coding"
          }
        },
        pageContent:{
          intro: {
            title: "Nightmare Valley",

            subTitle: "A game in the realm of nightmares",

            description: "During my MSc course in Human-Computer-Interaction at the [University of Nottingham](nottingham.ac.uk), we had a programming module in Python where we had to demonstrate our coding abilities by building a game using only the [PyGame](https://www.pygame.org) library. \n\n The game tells the story of a little girl who goes to sleep only to find out she is having her first nightmare ever, she has entered Nightmare Valley, and she desperately needs to escape it. \n\n ",

            // img: "https://eliasruiz.com/media/nightmare_valley_intro.4350521e.mp4",
            img: require("@/assets/images/nightmare_valley/nightmare_valley_intro.mp4"),
            // img: require("@/assets/images/nightmare_valley/nightmare_valley_intro_2.gif"),

            imgFooter: "**Fig.1.** The game start screen.",

            // type: "image",
            type: "video",

            // nline_css: "image-rendering: pixelated"

          },
          
          module_1:{
            title:"The Game",

            par1: "Only having three weeks to create the concept, design and code the videogame wasn't a lot of time and given my love for retro-games, I chose to go with pixel-art for the style of the game; taking inspiration from games such as Gris, Celeste and Limbo. \n\n The game is made up of two stages, the first linear level, where all the mechanics are introduced, and a second one with the final boss. The screen was split into blocks of 64x64 pixels, the minimum size of all the sprites. All the levels and sprites were designed by myself on an iPad using [‎Pixaki](https://rizer.co/pixaki/) and Photoshop on my laptop.  ",

            img: require("@/assets/images/nightmare_valley/nValley_1_grid.png"),
            // img: require("@/assets/images/nightmare_valley/nValley_1.png"),
            // img: require("@/assets/images/nightmare_valley/nightmare_valley_intro.mp4"),

            imgFooter: "**Fig.2.** Beginning of level 1", 

            // type: "video",
            type: "image",

            inline_css: "image-rendering: pixelated" 

          },

          module_2:{
            title:"The Art",

            par1: "Pixel-art, besides being a passion of mine, allowed me to get much more creative with the art of the game, considering we only had three weeks to complete it. All the sprites and sprite animations were created on my iPad using [‎Pixaki](https://rizer.co/pixaki/). ''",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/nightmare_valley/player.gif"),
                css: "",
              },
              img2:{
                type: "image",
                file: require("@/assets/images/nightmare_valley/boss.gif"),
                css: "",
              },
              img3:{
                type: "image",
                file: require("@/assets/images/nightmare_valley/enemy_1.gif"),
                css: "",
              },
              img4:{
                type: "image",
                file: require("@/assets/images/nightmare_valley/enemy_2.gif"),
                css: "",
              },
              img5:{
                type: "image",
                file: require("@/assets/images/nightmare_valley/enemy_3.gif")
              },
              img6:{
                type: "image",
                file: require("@/assets/images/nightmare_valley/enemy_4.gif"),
                css: "",
              },
            },
            
            gridNo: "grid-template-columns: repeat(3, 1fr);",

            
            inline_css: "image-rendering: pixelated; height: 80px; width: auto; position: relative; left: 50%; transform: translate3d(-50%,0,0); padding-bottom: 40px; padding-top: 10px;",

          },

          module_3: {
            title:"empty",

            par1: "",

            css2: "position: relative; top: -80px;",

            images: {
              img1: {
                type: "image",
                file: require("@/assets/images/nightmare_valley/sprite_sheet.png"),
              },
            },

            inline_css: "image-rendering: pixelated; height: auto; width: 100%;",

            gridNo: "grid-template-columns: repeat(1, 1fr);",

            },

          module_4: {
            title:"The Story",

            par1: "The game immerses the player into the very first nightmare a little girl has. She has entered Nightmare Valley, the place where all nightmares are created. \n\n The video below was created to introduce the basics of the story to the player, created again using Photoshop and Pixaki.",

            css2: "",

            images: {
              img1: {
                type: "video controls",
                file: require("@/assets/images/nightmare_valley/intro_story.mp4"),
              },
            },

            inline_css: "",

            gridNo: "",

            },

          module_5: {
            title:"The Boss",

            par1: "Given we only had three weeks to design and code the entire game, we had to make some compromises and decided only to have one long level followed by the boss battle and its minions. \n \n I was in charge of the design of the game as well as all the front end and level layout programming. Using the Pandas python library, we applied machine learning to the project by training two of the minions to predict the players' future position.",

            css2: "",

            images: {
              img1: {
                type: "video controls",
                file: require("@/assets/images/nightmare_valley/final_boss.mp4"),
              },
            },

            inline_css: "",

            gridNo: "",

            },

          module_6: {
            title:"The Final Game",

            par1:"The game has been made open source and is available on [GitHub](https://github.com/elrumo/Pyoneers) along with all of its original artwork and .PSDs, feel free to install it and play around with any of the files. \n \n",

            images: {
              img1: {
                type: "video controls",
                file: require("@/assets/images/nightmare_valley/the_game.mp4"),
              },
            },

            gridNo: "padding-bottom: 50px"

            },
          }

        }
    }
  }
</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
