<template lang="html">
  <div id="Home">
    <div id="mainBody" class="gridWrapper wrapper">
      <div class="headline">
        <h1>I’m <a rel="noopener" id="elias" href="#about">Elías</a>, <br> a Product Designer</span></h1>
        <h2>and this is what I’ve been up to:</h2>
      </div>

      <!-- Projects -->
      <div class="projects">
        <keep-alive v-for="project in projects" :key="project.title">
          <Project is="Project" :project="project"/>
        </keep-alive>
      </div>

      <!-- Arrow -->
      <div class="arrowGrid">
        <a rel="noopener" href="#about">
          <div class="arrowWrapper">
            <div class="arrow arrowBar"></div>
            <div class="arrow down arrowBar_Left"></div>
          </div>
        </a>
      </div>    

      <!-- About -->
      <About :about="about"/>

    </div>
  </div>
</template>

<script>
  import Project from "@/components/Project.vue"
  import About from "@/components/About.vue"
  export default {
    name: 'Home',
    data(){
      return{
        projects:{
          digitalSweetness:{
            title: "WebBites",
            tagOne: "#UX_Design",
            tagTwo: "#Front_End",
            description: "An accesible bookmarking service, built on FireBase, Node and Vue.",
            path: "/webbites",
            img: require("@/assets/images/digital_sweentess_project_page/webBites.jpg"),
            id: "digitalsweetness",
            idProject: "projectImageDigitalSweetness",
            onMouseOver: "onHover('projectImageDigitalSweetness')",
            onMouseOut: "onMouseout('projectImageDigitalSweetness')",
            linkType: "router",
          },

          ibm:{
            id: "ibm",
            onMouseOver: "onHover('projectImageIBM')",
            onMouseOut: "onMouseout('projectImageIBM')",
            path: "/ibm",
            idProject: "projectImageIBM",
            img: require("@/assets/images/ibm.jpg"),
            tagOne: "#Service_Design",
            tagTwo: "#Front_End",
            title: "IBM",
            description: "IBM Extrme Blue internship.",
            linkType: "router",
          },

          china:{
            id: "china",
            onMouseOver: "onHover('projectImageChina')",
            onMouseOut: "onMouseout('projectImageChina')",
            path: "/china",
            idProject: "projectImageChina",
            img: require("@/assets/images/china.jpg"),
            tagOne: "#Photography",
            tagTwo: "#Front_End",
            title: "China, seen by my lens",
            description: "A different view on Shanghái, Shenzhen and Beijing",
            linkType: "router",
          },

         macOSicons:{
            id: "macOSicons",
            onMouseOver: "onHover('projectImageMacOSicons')",
            onMouseOut: "onMouseout('projectImageMacOSicons')",
            path: "/macOSicons",
            idProject: "projectImageMacOSicons",
            img: require("@/assets/images/macOSicons/macOSicons_overview.jpg"),
            tagOne: "#UI/UX",
            tagTwo: "#Front_end",
            title: "macOSicons.com",
            description: "Open source icon platform with over 50K active monthly users.",
            linkType: "router",
          },

          nightmare_valley:{
            id: "nightmare_valley",
            onMouseOver: "onHover('projectImagePygame')",
            onMouseOut: "onMouseout('projectImagePygame')",
            path: "/nightmare_valley",
            idProject: "projectImagePygame",
            img: require("@/assets/images/nightmare_valley/Nightmare_valley_demo.gif"),
            tagOne: "#Game_Design",
            tagTwo: "#Python",
            title: "Nightmare Valley",
            description: "A Unity based video game.",
            linkType: "router",
          },

          playground:{
            id: "playground",
            onMouseOver: "onHover('projectImagePlayground')",
            onMouseOut: "onMouseout('projectImagePlayground')",
            path: "/playground",
            idProject: "projectImagePlayground",
            img: require("@/assets/images/space.gif"),
            tagOne: "#Experiments",
            tagTwo: "#Front_End",
            title: "My Playground",
            description: "Where I experiment, have fun and learn.",
            linkType: "router",

            moreProjects:{
              previous: {
                title: "Nightmare Valley",
                path: "/nightmare_valley"
              },
              next: {
                title: "WebBites",
                path: "/webbites"
              },
            }
          },
        },

        about:{
          title: "About",
          description1: 
            "I'm a designer and developer with an MSc in Human-Computer Interaction.\n\n I work at IBM Client Engieering as a designer and have previously worked at the [British Heart Foundation](https://www.bhf.org.uk/) as a product designer and at [SMAL](https://www.smal.de/), a Munich based design agency.",
          img: require("@/assets/images/about.jpg"),
        },

      };
    },
    components: {
      Project,
      About
    },
  }
</script>

<style lang="css">
  @import url("Home.css");
  @import url("snack-helper.min.css");
</style>
