<template>
<div class="wrapper pageWrapper">
    <div class="projectPageWrapper">

        <Intro :pageContent="pageContent.Intro"/>
        
        <Images :pageContent="pageContent.img1"/>
        <Images :pageContent="pageContent.img4"/>
        <Images :pageContent="pageContent.img3"/>
        <Images :pageContent="pageContent.img2"/>
    
        </div>

        <MoreProjects :projects="projects"/>
    </div>
</template>

<script>
    import Intro from "@/components/Intro.vue"
    import MoreProjects from "@/components/MoreProjects.vue"
    import TextOneImg from "@/components/TextOneImg.vue"
    import TextTwoImg from "@/components/TextTwoImg.vue"
    import Images from "@/components/Images.vue"

    export default {
        name: "Pride",
        components:{
            Images,
            Intro,
            MoreProjects,
            TextOneImg,
            TextTwoImg,
        },
        data(){
            return{
                projects:{
                    previous:{
                        title: "IBM",
                        path: "/ibm"
                    },
                    next:{
                        title: "Coding for kids",
                        path: "/coding"
                    }
                },
                pageContent:{
                    Intro:{
                        title: "IT'S NOT A BUG",

                        subTitle: "It's a feature",

                        description: "Historically the LGBT+ community has been a underepresented minority across sociaty, but even more so within the male dominated tech world. \n\n This is my small contribution to help normalise the reality that many in this industry are LGBT+; by using the popular term: 'It's not a bug, it's a feature' and giving it a new menaing.",

                        img: '',

                        imgFooter: ""
                    },
                    img1:{ 
                        img: require("@/assets/images/pride/not_a_bug2.jpg"),
                        imgFooter: ""
                    },
                    img2:{ 
                        img: require("@/assets/images/pride/not_a_bug1.jpg"),
                        imgFooter: ""
                    },
                    img3:{ 
                        img: require("@/assets/images/pride/not_a_bug3.svg"),
                        imgFooter: ""
                    },
                    img4:{ 
                        img: require("@/assets/images/pride/pride.svg"),
                        imgFooter: ""
                    },
                }
            }
        }
    }        
</script>

<style>

</style>