<template lang="html">
  <div class="projectPageWrapper wrapper">

        <Intro :pageContent="pageContent.intro"/>

    <!-- More Projects -->
    <MoreProjects :projects="moreProjects"/>
  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"
  import Intro from "@/components/Intro.vue"
  import TextOneImg from "@/components/TextOneImg.vue"
  import TextTwoImg from "@/components/TextTwoImg.vue"
  import Insights from "@/components/Insights.vue"
  import Embeded from "@/components/Embeded.vue"

  export default {
    name: 'Nightmare_Valley',

    components: {
      MoreProjects,
      GoBack,
      Intro,
      TextOneImg,
      Insights,
      TextTwoImg,
      Embeded,

    },

    data(){
      return{
        moreProjects:{
          previous: {
            title: "macOSicons",
            path: "/macosicons"
          },
          next: {
            title: "My Playground",
            path: "/playground"
          },
        },
        pageContent:{
          intro: {
            title: "Nightmare Valley",

            subTitle: "",

            description: "In late 2019 I built my first ever video game, (built on PyGame as I was learning Python) and in 2020 I finished building my second ever game, but this time it was built using Unity and C#. <br> <br> <span style='color:#FFF031'>[Play The Game on your browser](https://bit.ly/NightmareValley)</span>  <br> <br>  I designed and coded the game myself in a month, as an assignemnt for a module I took as part of my MSc in Human Computer Interaciton.",

            img: require("@/assets/images/nightmare_valley/Nightmare_valley_demo.mp4"),

            imgFooter: "",

            type: "video",
          }
        }
      }
    }
  }
</script>

<style lang="css">
  /* @import url("Cocacola.css"); */
</style>
