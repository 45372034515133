<template>
<div id="app">
  <router-view/>
</div>
</template>

<script type="text/javascript">

  export default {
    name: 'app',
    components: {
    },
    methods:{
    }
  }
</script>

<style>
  @import url("app.css");
</style>
