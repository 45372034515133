<template>
  <h2 class="goBack"><router-link rel="noopener" to="/">Go Home</router-link></h2>
</template>

<script>
export default {
    name:"GoBack"
}
</script>

<style>

</style>