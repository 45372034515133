<template>
  <section class="projectTextWrapper endOfSection">
          <h2 class="projectTextTile">
            <VueMarkdown> 
                    {{ pageContent.title }}
            </VueMarkdown> 
          </h2>

          <h3 class="projectPageDescription listText">
           <VueMarkdown> 
                    {{ pageContent.par1 }}
            </VueMarkdown> 
          </h3>

          <div class="insightsGridWrapper sourceSans">
            
            <ul class="insightsWrapper">
              <div class="bulletNumbers">1</div>
              <li>
                <VueMarkdown>
                  {{ pageContent.insight1 }}
                </VueMarkdown> 
              </li>
            </ul>

            <ul class="insightsWrapper">
              <div class="bulletNumbers">2</div>
              <li>
                  <VueMarkdown>
                    {{ pageContent.insight2 }}
                  </VueMarkdown> 
              </li>
            </ul>

            <ul class="insightsWrapper">
              <div class="bulletNumbers">3</div>
              <li>
                  <VueMarkdown>
                    {{ pageContent.insight3 }}
                  </VueMarkdown> 
              </li>
            </ul>

            <ul class="insightsWrapper">
              <div class="bulletNumbers">4</div>
              <li>
                  <VueMarkdown>
                    {{ pageContent.insight4 }}
                  </VueMarkdown> 
              </li>
            </ul>

            <ul class="insightsWrapper">
              <div class="bulletNumbers">5</div>
              <li>
                  <VueMarkdown>
                    {{ pageContent.insight5 }}
                  </VueMarkdown> 
              </li>

            </ul>

            <ul class="insightsWrapper">
              <div class="bulletNumbers">6</div>
              <li>
                  <VueMarkdown>
                    {{ pageContent.insight6 }}
                  </VueMarkdown> 
              </li>
            </ul>

          </div>
          <br>
        </section>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: "Insights",
    props:{
        pageContent:{}
    },
    components:{
      VueMarkdown
    }

}
</script>

<style>

</style>