<template>
  <section class="moreProjects">
      <h2 class="previousProject"><router-link rel="noopener" :to="projects.previous.path">{{ projects.previous.title }}</router-link></h2>
      <h2 class="nextProject"><router-link rel="noopener" :to="projects.next.path">{{ projects.next.title }}</router-link></h2>
    </section>
</template>

<script>
  export default {
    name: "MoreProjects",
    props:{
        projects:{}
    }

  }
</script>

<style>

</style>