<template>
  <section class="projectTextWrapper endOfSection" :style="pageContent.css2">
        <div v-if="pageContent.title != 'empty'">
            <h2 class="projectTextTile"> {{ pageContent.title }} </h2>
            <h3 class="projectPageDescription">
                <vue-markdown> 
                    {{ pageContent.par1 }}
                </vue-markdown>
            </h3>
        </div>
        <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            <div v-for="image in pageContent.images">
                <img v-if="image.type === 'image big'" class="projectPageImage breakPageImage img" :style="pageContent.inline_css_big" :src="image.file">
                <video v-else-if="image.type === 'video big'" class="projectPageImage breakPageImage img" :src="image.file" autoplay muted loop playsinline poster="" controls></video>
            </div>
            
            <div class="twoImagesGrid" :style="pageContent.gridNo">
                <div v-for="image in pageContent.images">
                    <img v-if="image.type == 'image'" class="projectPageImage breakPageImage img" :style="pageContent.inline_css" :src="image.file">
                    <video v-else-if="image.type === 'video'" class="projectPageImage breakPageImage img" :src="image.file" autoplay muted loop playsinline controls poster=""></video>
                    <video v-else-if="image.type === 'video controls'" class="projectPageImage breakPageImage img" :src="image.file" autoplay muted loop controls playsinline poster=""></video>
                   <iframe v-else-if="image.type === 'iframe'" :src="image.file" frameborder="0"></iframe>
                </div>
            
            </div>
           <h4 class="footNote"><VueMarkdown> {{ pageContent.imgFooter }}</VueMarkdown></h4>
           <h4 class="footNote"><VueMarkdown> {{ pageContent.imgFooter2 }}</VueMarkdown></h4>
        </div>

        <!-- <div class="projectPageImage breakPageImageWrapper imageBottomPading imageTopPading">
            <img class="projectPageImage breakPageImage" :src="pageContent.img" alt="">
            <h4 class="footNote"><VueMarkdown> {{ pageContent.imgFooter }}</VueMarkdown></h4>
        </div> -->
    </section>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
    name: "TextTwoImg",
    props:{
        pageContent:{}
    },
    components:{
        VueMarkdown
        // GoBack,
        // MoreProjects
    }

}
</script>

<style>

</style>