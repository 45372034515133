<template>
  <div :id="project.id" class="projectWrapper" @mouseover="onHover(project.idProject)" @mouseleave="onMouseout(project.idProject)">
        <router-link rel="noopener" v-if="project.linkType === 'router'" :id="project.id" class="projectTitle" :to="project.path">
            <div class="projectImage" :id="project.idProject">
                <img class="img" :src="project.img" alt="">
                <div class="projectTypeWrapper">
                    <p class="projectType">{{  project.tagOne }}</p>
                    <p class="projectType">{{  project.tagTwo }}</p>
                </div>
            </div>
        </router-link>

        <a rel="noopener" v-if="project.linkType === 'url'" target="_blank" class="projectTitle" :href="project.path">
            <div class="projectImage" :id="project.idProject">
                <img class="img" :src="project.img" alt="">
                <div class="projectTypeWrapper">
                    <p class="projectType">{{ project.tagOne }}</p>
                    <p class="projectType">{{ project.tagTwo }}</p>
                </div>
            </div>
        </a>
        
        <router-link
            rel="noopener"
            v-if="project.linkType === 'router'"
            :id="project.id"
            class="projectTitle"
            :to="project.path"
        >
            {{ project.title }}
        </router-link>

        <a v-if="project.linkType === 'url'" target="_blank" class="projectTitle" :href="project.path">
            {{ project.title }}
        </a>

        <VueMarkdown class="projectDescription">
           {{ project.description }}
        </VueMarkdown>

    </div>
</template>

<script>
    
    import VueMarkdown from 'vue-markdown'

    var offX = 10;// X offset from mouse position
    var offY = -10; // Y offset from mouse position

    export default {
        name: "Project",
        props:{
            project: {},
            // tagTwo: "",
        },
        components:{
            VueMarkdown
        },
        data(){ 
            return{
                // tagOne: ""
            
            };
        },
        methods:{
            //Get mouse position
            mouseX(evt) {
                let parent = this
                if (!evt) evt = window.event;
                if (evt.pageX) return evt.pageX;
                else if (evt.clientX) return evt.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft); else return 0;
            },
            mouseY(evt) {
                let parent = this
                if (!evt) evt = window.event;
                if (evt.pageY) return evt.pageY;
                else if (evt.clientY) return evt.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop); else return 0;
            },

            //Offset image relative to window width
            moveObjBy(evt, obj) {
                let parent = this
                var gridWidth = parseInt($('.wrapper').outerWidth(false)); //Get wrapper witdh
                var windowWidth = parseInt($(window).width()); //Get window witdh
                var offSet = (windowWidth - gridWidth) / 2; //Get distance between one grid side and window
                // console.log(offSet);
                obj.left = (parseInt(parent.mouseX(evt)) - offSet + offX) + 'px';
                obj.top = (parseInt(parent.mouseY(evt)) + offY) + 'px';
            },
            
            onHover(id){
                let parent = this
                document.getElementById(id).style.display = "block";
                document.onmousemove = function(evt){
                    var obj = document.getElementById(id).style;
                    parent.moveObjBy(evt, obj);
                };
            },

            onMouseout(id) {
                let parent = this
                document.getElementById(id).style.display = "none"
            }
        }

    }

</script>

<style>

</style>