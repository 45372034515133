<template lang="html">
  <div id="Home">
    <section class="hero-wrapper">
         <div id="hero" class=" hero-img-mask lazyload"> </div>
        <header class="parallax-h1">
          <h1 class="hero-title">CHINA</h1>
        </header>
        <div id="hero" class="lazyload hero-img"></div>
    </section>

    <section class="about-wrapper">
      <div class="about-text-wrapper">
        <GoBack/>
        <h1 class="about-h1">
          As seen by lens
        </h1>
        <h2 style="margin-bottom: 55px; padding-top: 2px">
          A trip to China
        </h2>
        <div class="about-body">
          On July 16th 2018, after my last day at the office, I rushed to Munich Airport to begin what would become the trip of my life. A day later, I was in London about to depart on an 11-hour flight to China, and I spent the next 34 days across three different megacities: Shenzhen, Shanghai and Beijing. <br> <br> The first three weeks were spent in Shenzhen after winning a scholarship by <a href="http://www.lboro.ac.uk/" target="_blank" rel="noopener">Loughborough Univerity</a> to attend the<a href="https://www.inacademy.eu/apia/" target="_blank" rel="noopener"> Asia Pacific Innovation Academy</a> program. You can read more about my experience as a product designer in China on <a href="https://medium.com/@elrumo/china-the-future-of-digital-product-design-68fa7be383c0" target="_self" rel="noopener">Medium</a>. This site, however, focus on the artistic and photo-journalist side of the trip.
        </div>
      </div>
      <div class="parallax-about-img">
         <img class="lazyload about-img" src="@/assets/images/china/china-about.jpg" alt="me and my true love">
      </div>
    </section>

    <section class="img-gallery-wrapper">
      <div class="">
        <img id="img1" class="lazyload" src="@/assets/images/china/gallery/China_2018-365.jpeg" alt="">
        <video type="video/webm" class="columnImages" autoplay="true" playsinline muted loop>
          <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_11.webm" type="video/webm">
          <source src="@/assets/images/china/video/horizontal/China_clip_11.mp4" type="video/mp4">
        </video>
      </div>
      <div class="">
        <video class="columnImages" playsinline autoplay muted loop >
          <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_23.webm" type="video/webm">
          <source src="@/assets/images/china/video/horizontal/China_clip_23.mp4" type="video/mp4">
        </video>
        <img id="img3" class="lazyload" src="@/assets/images/china/gallery/China_2018-49.jpeg" alt="">
      </div>
      <div class="">
        <img id="img8" class="lazyload" src="@/assets/images/china/gallery/China_2018-600.jpeg" alt="">
        <video class="columnImages" playsinline autoplay muted loop >
          <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_10.webm" type="video/webm">
          <source src="@/assets/images/china/video/horizontal/China_clip_10.mp4" type="video/mp4">
        </video>
      </div>
      <div class="">
        <video class="columnImages" playsinline autoplay muted loop >
          <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_27.webm" type="video/webm">
          <source src="@/assets/images/china/video/horizontal/China_clip_27.mp4" type="video/mp4">
        </video>
        <img id="img7" class="lazyload" src="@/assets/images/china/gallery/China_2018-592.jpeg" alt="">

      </div>
    </section>

    <section class="stripGallery">
      <div class="columnTextWrap">
        <h1>Shenzhen</h1>
        <h2>The city that keeps the world -and your phone- going.</h2>
      </div>

      <div class="strip">
        <div id="column1" class="column">
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source src="@/assets/images/china/video/vertical/China_Vertical_15.mp4" type="video/mp4">
            <source class="lazyload" src="@/assets/images/china/video/vertical/China_Vertical_15.webm" type="video/webm">
          </video>
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-320.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-149.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-140.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-283.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-327.jpeg" alt="">
        </div>

          <div id="column2" class="column">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-75.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-9.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-379.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-370.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-293.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-173.jpeg" alt="">
          </div>

          <div id="column3" class="column">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-35.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-330.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-280.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-263.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-74.jpeg" alt="">
            <video class="columnImages wow fadeInUp crop" playsinline autoplay muted loop >
              <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_13.webm" type="video/webm">
              <source src="@/assets/images/china/video/horizontal/China_clip_13.mp4" type="video/mp4">
            </video>
          </div>

          <div id="column4" class="column">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-334.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-98.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-379.jpeg" alt="">
            <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
              <source class="lazyload" src="@/assets/images/china/video/vertical/China_Vertical_5.webm" type="video/webm">
              <source src="@/assets/images/china/video/vertical/China_Vertical_5.mp4" type="video/mp4">
            </video>
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-253.jpeg" alt="">
            <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shenzhen/China_2018-118.jpeg" alt="">
          </div>
        </div>
      
        <!-- Shanghai -->
      <div class="columnTextWrap">
        <h1>Shanghai</h1>
        <h2>Where the east meets the west.</h2>
      </div>

      <div class="strip">
        <div id="column1" class="column">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-416.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-420.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-474.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-469.jpeg" alt="">
        </div>

        <div id="column2" class="column">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-473.jpeg" alt="">
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_25.webm" type="video/webm">
            <source src="@/assets/images/china/video/horizontal/China_clip_25.mp4" type="video/mp4">
          </video>
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-452.jpeg" alt="">
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_24.webm" type="video/webm">
            <source src="@/assets/images/china/video/horizontal/China_clip_24.mp4" type="video/mp4">
          </video>

        </div>

        <div id="column3" class="column">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-438.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-484.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-418.jpeg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-444.jpeg" alt="">
        </div>

        <div id="column4" class="column">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-431.jpeg" alt="">
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_20.webm" type="video/webm">
            <source src="@/assets/images/china/video/horizontal/China_clip_20.mp4" type="video/mp4">
          </video>
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/shangai/China_2018-450.jpeg" alt="">
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_23.webm" type="video/webm">
            <source src="@/assets/images/china/video/horizontal/China_clip_23.mp4" type="video/mp4">
          </video>
        </div>
      </div>
       <!-- Beijing -->
      <div class="columnTextWrap">
        <h1>Beijing</h1>
        <h2>Remembering the past to shape the future.</h2>
      </div>

      <div class="strip">
        <div id="column1" class="column">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-629.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-492.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-524.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-640.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-510.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-614.jpg" alt="">

        </div>

        <div id="column2" class="column">
          <!-- <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-500.jpg" alt=""> -->
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-499.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-537.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-501.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-590.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-604.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-600.jpg" alt="">
          <!-- <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-652.jpg" alt=""> -->
        </div>

        <div id="column3" class="column">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-637.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-596.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-626.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-620.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-648.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-652.jpg" alt="">
        </div>

        <div id="column4" class="column">
          <!-- <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-508.jpg" alt=""> -->
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source class="lazyload" src="@/assets/images/china/video/horizontal/China_clip_28.webm" type="video/webm">
            <source src="@/assets/images/china/video/horizontal/China_clip_28.mp4" type="video/mp4">
          </video>
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-523.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-638.jpg" alt="">
          <video class="columnImages wow fadeInUp" playsinline autoplay muted loop >
            <source class="lazyload" src="@/assets/images/china/video/vertical/China_Vertical_22.webm" type="video/webm ">
            <source src="@/assets/images/china/video/vertical/China_Vertical_22.mp4" type="video/mp4">
          </video>
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-643.jpg" alt="">
          <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-605.jpg" alt="">
          <!-- <img class="columnImages wow fadeInUp lazyload" src="@/assets/images/china/beijing/China_2018-642.jpg" alt=""> -->
        </div>
      </div>
    </section>

    <!-- More Projects -->
    <MoreProjects :projects="moreProjects"/>

  </div>
</template>

<script>
  import MoreProjects from "@/components/MoreProjects.vue"
  import GoBack from "@/components/GoBack.vue"

  export default {
    name: 'China',
    data(){
      return{
        moreProjects:{
          previous: {
            title: "IBM",
            path: "/ibm"
          },
          next: {
            title: "macOSicons",
            path: "/macOSicons"
          },
        }
      };
    },
    components: {
      GoBack,
      MoreProjects
    },
  }

  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop();
      $('.parallax-h1').css('top', (scrollTop * -0.5) + 'px');
    });}
  else {
    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop();
      $('.parallax-h1').css('top', (scrollTop * -0.3) + 'px');
      $('.parallax-about-img').css('top', (scrollTop * -0.2 ) + 'px');
    });
  }

</script>

<style lang="css">
  @import url("China.css");
  @import url("animate.css");
</style>
